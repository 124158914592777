.search-results-chapter {
	margin-bottom: $space-lg * 2;

	&-heading {
		position: relative;
		width: max-content;
		cursor: pointer;
		display: flex;
		align-items: baseline;
		margin-bottom: $space-xl;

		h3 {
			position: relative;
			margin-bottom: 0;
			margin-right: 18px;

			> span {
				display: block;
			}
		}

		.ip-search-icon {
			top: 7px;
			left: -24px;
			font-size: 1rem;
			color: $primary;
		}
	}

	&-count {
		color: $quaternary;
		font-style: italic;
	}

	.results-table {
		margin-bottom: $space-xl * 2;

		.row {
			.main-data,
			.additional-data .table-data {
				opacity: .5;
			}	
			
			&.search-result {
				.main-data,
				.additional-data .table-data {
					opacity: 1;
				}
			}
		}
	}
}
