// import the atom variables
@import 'variables';

/**
*     @additional information
*     all inputs are basically extended with the input atom and are the base for %input-checkbox
*/


%input-checkbox {
	display: $checkbox-display;
	width: $checkbox-width;
	margin: $checkbox-margin;
	cursor: $checkbox-cursor;
	outline: $checkbox-outline;
}

%input-styled-checkbox {
	position: relative;
	display: block;
	height: 2em;
	margin-bottom: $space-md;
	line-height: 1.9em;
	text-indent: 2.3em;
	cursor: pointer;

	&:hover {
		input[type='checkbox']::before {
			border-color: $input-background-color;
		}

		input[type='checkbox']::after {
			opacity: .4;
		}
	}

	input[type='checkbox'] {
		position: absolute;
		display: block;
		float: left;
		width: 1.7em;
		height: 1.7em;
		line-height: 1.5em;
		appearance: none;

		&::before {
			position: absolute;
			top: 1px;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-color: $input-background-color;
			border: $input-border;
			border-color: $input-background-color;
			border-radius: $border-radius;
			box-shadow: 0 2px 5px -2px rgba(0,0,0,.4);
			content: '';
			box-sizing: border-box;
			transition: all .3s $ease-in-out-quint;
		}

		&::after {
			position: absolute;
			top: 51%;
			left: 49%;
			width: 15px;
			height: 8px;
			background: transparent;
			border: 3px solid $primary;
			border-top: 0;
			border-right: 0;
			content: '';
			opacity: 0;
			transform-origin: -0% -0%;
			transform: rotate(-45deg) scale(.55) translateX(-35%) translateY(-65%);
			transition: all .3s $ease-in-out-quint;
		}

		&:checked {

			&::before {
				border-color: $input-background-color;
			}

			&::after {
				opacity: 1;
				transform-origin: -0% -0%;
				transform: rotate(-45deg) scale(1) translateX(-35%) translateY(-65%);
			}
		}
	}
}
