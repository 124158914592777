$table-width:               100% !default;
$table-margin:              calc($line-height / 2) 0 !default;
$table-border-collapse:     collapse !default;
$table-table-layout:        fixed !default;

$th-padding:                calc($line-height / 2) 0 !default;
$th-font-weight:            bold !default;
$th-text-align:             left !default;
$th-vertical-align:         top !default;
$th-border-bottom:          $border-width $border-style darken($border-color, 15%) !default;

$td-padding:                calc($line-height / 2) 0 !default;
$td-vertical-align:         top !default;
$td-border-bottom:          $border-width $border-style $border-color !default;

$tr-vertical-align:         top !default;
