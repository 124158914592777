@import '../../_molecules/modal/variables';

.modal {
	position: absolute;

	.modal-backdrop {
		position: fixed;
		top: $header-height;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 998;
		display: flex;
		width: 100%;
		height: 100%;
		background-color: transparentize($modal-backdrop-bg-color, .7);
	}

	.modal-box {
		position: relative;
		background: $modal-box-bg-color;
		box-shadow: $box-shadow;

		.close {
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}

.modal.center {
	.modal-backdrop {
		align-items: center;
		justify-content: center;
	}

	.modal-box {
		display: flex;
		flex-direction: column;
		width: 90%;
		max-width: 560px;
		max-height: 70vh;
		padding-top: $space-xl;
		padding-bottom: $space-md;
		margin-bottom: $header-height;
		overflow: hidden;
	}

	.modal-icon {
		position: absolute;
		top: 32px;
		left: 16px;
		font-size: 1.375rem;
		color: $primary;
	}

	.modal-title,
	.modal-content {
		padding-right: 46px;
		padding-left: 46px;
	}

	.modal-title {
		margin-top: 0;
		font-size: 1.375rem;
	}

	.modal-content {
		margin-bottom: $space-xl;
		overflow-y: scroll;
	}

	.modal-actions {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		margin: 0 $space-xl;

		button {
			margin-bottom: $space-lg;

			&:last-of-type {
				margin-left: 15px;
			}
		}
	}

	&.lightbox {
		.modal-box {
			max-width: unset;
			max-height: unset;
			padding-bottom: $space-xl;
			width: auto;
			height: auto;
		}

		.modal-content {
			margin-bottom: 0;
			max-width: calc(90vw - #{$space-xl * 2});
			max-height: calc(94vh - 96px);
			
			img {
				display: block;
				width: 100%;
				height: 100%;
				max-width: 1920px;
				max-height: unset;
				object-fit: contain;
			}
		}
	}
}

.modal.file-info {

	.modal-backdrop {
		align-items: top;
		justify-content: flex-start;
	}

	.modal-box {
		min-width: 260px;

		.modal-content {
			height: 100%;
			padding: $modal-file-info-padding-vert $modal-file-info-padding-hor;
			overflow-x: scroll;

			> *:last-child {
				margin-bottom: $modal-file-info-padding-vert; //to be able to have the padding on bottom, when scrolling
			}
		}

		dl {
			margin-bottom: $space-lg * 5;
		}

		a {
			display: block;
			margin-bottom: $space-md;
		}
	}
}
