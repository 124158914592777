// import the atom variables
@import 'variables';

%paragraph {
	@media (max-width: $phone-landscape) {
		font-size: $paragraph-phone-font-size;
	}
	margin: $paragraph-margin;
	font-family: $paragraph-font-family;
	font-size: $paragraph-font-size;

	* {
		font-family: $paragraph-font-family;
	}

	code,
	pre {
		@extend %code;
	}
}
