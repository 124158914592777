
%flex-grid {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	max-width: $flex-grid-max-width;

	> div[class^='col-'] {
		min-height: 50px;
	}
}

.flex-grid {
	@extend %flex-grid;
}

@for $columns from 1 through $flex-grid-columns {
	%col-#{$columns}of#{$flex-grid-columns} {

		@if $columns == $flex-grid-columns {
			width: 100%;
		} @else {
			width: calc(($columns / $flex-grid-columns * 100 * 1%) - $flex-grid-gap) ;
		}

		margin-right: $flex-grid-gap;

		&:last-child {
			margin-right: 0;
		}
	}

	.col-#{$columns}of#{$flex-grid-columns} {
		@extend %col-#{$columns}of#{$flex-grid-columns};
	}
}

@mixin columns($columns) {
	@if $columns == $flex-grid-columns {
		width: 100%;
	} @else {
		width: calc($columns / $flex-grid-columns * 100 * 1% - $flex-grid-gap) ;
	}
	margin-right: $flex-grid-gap;

	&:last-child {
		margin-right: 0;
	}
}
