$icomoon-font-family: 'ip-search-icons' !default;

$icon-add: '\e900';
$icon-arrow-down: '\e918';
$icon-arrow-left: '\e901';
$icon-arrow-right: '\e902';
$icon-calendar: '\e916';
$icon-check: '\e903';
$icon-close: '\e904';
$icon-delete: '\e905';
$icon-download: '\e906';
$icon-feedback: '\e917';
$icon-filter: '\e907';
$icon-highlight: '\e909';
$icon-highlight-add: '\e908';
$icon-information: '\e90a';
$icon-link: '\e90b';
$icon-notes: '\e90c';
$icon-notes-indicator: "\e919";
$icon-pdf: '\e90d';
$icon-plus: '\e90e';
$icon-print: '\e90f';
$icon-search: '\e910';
$icon-tag: '\e912';
$icon-tag-add: '\e911';
$icon-visibility-off: '\e913';
$icon-visibility-on: '\e914';
$icon-warning: '\e915';
