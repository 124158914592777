
.notes {
	position: fixed;
	top: $header-height;
	right: 0;
	z-index: 50;
	display: flex;
	flex-direction: column;
	width: calc(25% + 28px);
	height: calc(100vh - #{$header-height});
	min-width: 360px;
	padding: $space-lg 0 0;
	background-color: $white;

	
	.notes-title {
		padding-left: $space-xl;
		font-weight: 600;
	}

	.close {
		position: absolute;
		top: $space-md;
		right: $space-md;
	}

	.notes-new {
		padding: $space-xl;
	}

	.notes-existing {
		height: 100%;

		ul {
			padding: 0;
			margin: 0;
			list-style: none;
		}
	}
}
