.brand {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	img {
		align-self: center;
		width: 40px;
	}
}
