// omega-reset mixin
@mixin omega-reset($nth) {
	&:nth-child(#{$nth}) { margin-right: flex-gutter(); }
	&:nth-child(#{$nth}+1) { clear: none }
}

html { // http://bit.ly/1qk2tVR
  box-sizing: border-box;
  font-family: $font-primary;
}

* {
  &,
  &::after,
  &::before {
	  box-sizing: inherit;
  }
}

body {
	font-size: $font-size;
	line-height: $line-height-unitless;
	color: $font-color;
	-webkit-font-smoothing: antialiased;
	overflow-y: scroll;
}

.app {
	// height: 100vh;

	> .ScrollbarsCustom {
		height: 100vh;
	}

	.main {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}

	.content-centered {
		display: flex;
		align-items: center;
		flex-direction: column;
		max-width: 760px;
		text-align: center;
	}
}

.view {
	min-height: calc(100vh - #{$header-height} - #{$footer-height});
	padding: $space-lg $space-lg * 4 $space-lg * 2;
	margin-top: $header-height;
	background-color: $light-gray;
}

.ScrollbarsCustom {
	width: 100%;

	.ScrollbarsCustom-Wrapper {
		inset: 0 10px 0 0;
		width: 100%;
	}

	.ScrollbarsCustom-Content {
		min-width: 100%;
		min-height: 100%;
		box-sizing: border-box;
	}

	.ScrollbarsCustom-Track {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 999999;
		width: 10px;
		height: 100%;
		overflow: hidden;
		background: rgba($senary, .3);
		user-select: none;
	}

	.ScrollbarsCustom-ThumbY {
		width: 100%;
		cursor: pointer;
		background: $senary;
		transform: translateY(0);
	}
}
