.citation-header {
	.citation-control {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 72px;
		padding: 0 20px;
		background-color: $septenary;
		border-radius: $border-radius;

		.citation-control-data {
			position: relative;
			display: flex;
			align-items: center;

			.ip-search-icon,
			.pub-number,
			.kind-code,
			.npl-title,
			.registry-no {
				font-size: 1.25rem;
			}

			.ip-search-icon {
				position: absolute;
				left: 0;
				cursor: pointer;
			}

			.link {
				color: $primary;
				text-decoration: underline;
			}

			.link,
			.kind-code {
				font-weight: 700;
			}

			.pub-number,
			.npl-title,
			.registry-no {
				margin: 0 10px 0 48px;
			}


			.npl-title,
			.registry-no {
				display: block;
				max-width: 60vw;
			}
		}

		.citation-control-action {
			display: flex;
			align-items: baseline;
			justify-content: flex-end;
			width: 38%;

			button {
				background-color: $white;
			}

			> *:nth-last-child(n+2) {
				margin-right: 20px;
			}
		}
	}

	&.sticky {
		.citation-control {
			position: fixed;
			top: $header-height;
			left: 0;
			width: 100vw;
			padding-right: $space-xl * 2.5;
			padding-left: $space-xl * 2.5;
		}
		
		&+.citation-content {
			margin-top: $header-height;
		}
	}
}