.citation-tags {
    margin-top: -8px;
	 display: flex;
	 flex-wrap: wrap;
	 align-items: center;

    .tag {
        height: 38px;
        padding-left: 5px;
        padding-right: 26px;
    }

    .icon-close {
        display: none;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 38px;
        font-size: .6rem;
        cursor: pointer;
    }

    .tag:hover {
        background-color: $septenary;
        padding-right: 0;

        .icon-close {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    button {
        display: inline-flex;
        align-items: center;
        padding: 0 16px;
        background-color: $white;
        font-size: .9em;

        &.add-tag {
            display: inline-flex;
            align-items: center;
            background-color: $primary;
            border-radius: 0;
            height: inherit;
        }
        
        .icon-tag-add {
            font-size: 1.4em;
        }
    }

    .add-tag-container {
        display: inline-flex;
        position: relative;

        ::-webkit-scrollbar {
            width: 10px;
        }
        
        ::-webkit-scrollbar-track {
            background-color: $white;
        }

        ::-webkit-scrollbar-thumb {
            background-color: $septenary-d1;
        }
        
        ::-webkit-scrollbar-thumb:hover {
            cursor: pointer;
            background-color: $septenary-d1;
        }

        .add-tag-dropdown {
            position: absolute;
            top: 42px;
            right: 0;
            left: 0;
            padding: 0;
            font-weight: 600;
            list-style: none;
            cursor: pointer;
            background-color: $white;
            border-top: 2px solid $primary;
            box-shadow: $box-shadow;
            max-height: 220px;
            overflow-y: overlay;

            ul {
                position: absolute;
            }
    
            li {
                position: relative;
                display: flex;
                align-items: center;
                padding: $space-md $space-md;
    
                &:hover {
                    background-color: $septenary;
                }
    
                .ip-search-icon {
                    font-size: 1.25rem;
                    color: $primary;
                }
            }

            .tag-name {
                font-size: 1.125rem;
                font-weight: normal;
                padding-left: 30px;
            }
        }
    }
}