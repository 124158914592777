.report-content {

	h2 {
		margin-top: 0;
	}

	.report-content-section + .report-content-section {
		margin-top: $space-xl * 3;
	}

	dl {
		dt {
			font-weight: 600;
			text-transform: none;
			color: $font-color;
			font-size: 1.125rem;
			margin-top: $space-lg;
		}
	}

	p,
	div {
		font-size: 1.125rem;
		line-height: 28px;
	}

	.external-html {
		white-space: pre-wrap;
	}
}
