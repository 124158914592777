.results-information {
	@include slide-open-animaiton(500px, .2s, .125s);
	position: relative;
	padding-bottom: $space-xl;

	header {
		margin-bottom: $space-lg;
	}

	p {
		width: calc(100% - 90px);
		hyphens: auto;
		columns: 2;
		column-gap: $space-xl * 2;
	}

	.close {
		position: absolute;
		top: 0;
		right: 0;
	}
}
