.family-members-table {
	.row {
		position: relative;
		cursor: pointer;
		margin-left: $space-md;
		margin-right: $space-md;

		&:nth-child(odd) {
			background-color: $septenary;
		}

		&:hover {
			z-index: 10;
			box-shadow:
				0 .1px 1.4px -2px rgba(0, 0, 0, .012),
				0 .4px 1.7px -2px rgba(0, 0, 0, .022),
				0 .9px 2.2px -2px rgba(0, 0, 0, .031),
				0 2.1px 2.9px -2px rgba(0, 0, 0, .044),
				0 6px 4px -2px rgba(0, 0, 0, .07)
			;
		}
	}

	&.ScrollbarsCustom {
		height: 30vh;

		&.appear {
			height: 0;
		}

		&.enter-active {
			height: 8vh;
		}

		&.enter-done {
			height: 30vh;
		}

		&.exit {
			height: 30vh;
		}

		&.exit-active {
			height: 12vh;
		}

		&.exit-done {
			height: 0;
		}

		.ScrollbarsCustom-Track {
			right: -10px;
		}
	}
}
