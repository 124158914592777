// input focus
$input-focus-color:             rgb(50, 50, 50) !default;
$input-focus-border-color:      none;

// attributes for all inputs
$input-display:                 block !default;
$input-width:                   100% !default;
$input-padding:                 calc($line-height / 3) calc($line-height / 2) !default;
$input-margin:                  0 0 calc($line-height / 2) 0 !default;
$input-background-color:        $white !default;
$input-border:                  $border-width $border-style $septenary;
$input-border-radius:           2px;
$input-outline:                 none !default;
$input-box-shadow:              
0 .3px .4px -2px rgba(0, 0, 0, .028),
0 .9px 1.3px -2px rgba(0, 0, 0, .042),
0 4px 6px -2px rgba(0, 0, 0, .07)
;


$input-box-sizing:              border-box !default;
$input-placeholder-color:       $quinary;

// special input fields with predefined content
$input-before-content: attr(placeholder) !default;
$input-before-color: $input-placeholder-color !default;
$input-before-margin-right: $space-md !default;
