.icon-text {
	display: flex;
	align-items: center;
	font-weight: 600;
	color: $black;

	.ip-search-icon {
		margin-right: 10px;
		color: $primary;
	}
}
