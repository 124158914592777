@import "variables.scss";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('icomoon.eot?d4hd4v');
  src:  url('icomoon.eot?d4hd4v#iefix') format('embedded-opentype'),
    url('icomoon.ttf?d4hd4v') format('truetype'),
    url('icomoon.woff?d4hd4v') format('woff'),
    url('icomoon.svg?d4hd4v#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ip-search-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add {
	&::before {
		content: $icon-add;
	}
}

.icon-arrow-down {
	&:before {
		content: $icon-arrow-down;
	}
}

.icon-arrow-left {
	&::before {
		content: $icon-arrow-left;
	}
}

.icon-arrow-right {
	&::before {
		content: $icon-arrow-right;
	}
}

.icon-calendar {
	&::before {
		content: $icon-calendar;
	}
}

.icon-check {
	&::before {
		content: $icon-check;
	}
}

.icon-close {
	&::before {
		content: $icon-close;
	}
}

.icon-delete {
	&::before {
		content: $icon-delete;
	}
}

.icon-download {
	&::before {
		content: $icon-download;
	}
}

.icon-feedback {
	&::before {
		content: $icon-feedback;
	}
}

.icon-filter {
	&::before {
		content: $icon-filter;
	}
}

.icon-highlight-add {
	&::before {
		content: $icon-highlight-add;
	}
}

.icon-highlight {
	&::before {
		content: $icon-highlight;
	}
}

.icon-information {
	&::before {
		content: $icon-information;
	}
}

.icon-link {
	&::before {
		content: $icon-link;
	}
}

.icon-notes {
	&::before {
		content: $icon-notes;
	}
}

.icon-notes-indicator {
	&::before {
		content: $icon-notes-indicator;
	}
}

.icon-pdf {
	&::before {
		content: $icon-pdf;
	}
}

.icon-plus {
	&::before {
		content: $icon-plus;
	}
}

.icon-print {
	&::before {
		content: $icon-print;
	}
}

.icon-search {
	&::before {
		content: $icon-search;
	}
}

.icon-tag-add {
	&::before {
		content: $icon-tag-add;
	}
}

.icon-tag {
	&::before {
		content: $icon-tag;
	}
}

.icon-visibility-off {
	&::before {
		content: $icon-visibility-off;
	}
}

.icon-visibility-on {
	&::before {
		content: $icon-visibility-on;
	}
}

.icon-warning {
	&::before {
		content: $icon-warning;
	}
}
