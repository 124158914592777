.#{$rt-namespace}__close-button {
	@extend .ip-search-icon ;

	align-self: center;
	height: 100%;
	padding: 12px;
	font-size: .75rem;
	color: $light-gray;
	cursor: pointer;
	background: transparent;
	border: 0;
	outline: none;
	opacity: 1;
	transition: .3s ease;

	&::before {
		content: '\e904';
	}

	&--light {
		color: #000;
		opacity: .3;
	}

	& > svg {
		display: none;
	}

	&:hover,
	&:focus {
		color: $white;
		background-color: transparent;
	}
}
