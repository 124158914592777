.loading-screen {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	height: 100%;
	padding: $space-xl * 3 0;

	.loading-animation {
		margin-bottom: $space-lg;
	}
}
