@import 'variables';

.search {
	position: relative;

	.search-field {
		position: relative;
		display: flex;
		align-items: center;
		height: 100%;
		padding-right: $space-xl * 2;
		padding-left: $space-lg;
		background-color: $septenary;

		.search-field-inner {
			position: relative;
			display: flex;
			align-items: center;
			
			width: 100%;
			overflow: hidden;

			&.overflow {
				justify-content: flex-end;
			}
		}

		.search-selected {
			> div {
				display: flex;
				align-items: center;
				font-weight: 600;
			}

			&.search-for-tags,
			&.search-for-highlights {
				.ip-search-icon {
					margin-right: $space-md;
					font-size: 1.375rem;
					color: $primary;
				}
			}

			&.search-for-highlight,
			&.search-for-tag {
				> div {
					max-width: 200px;
					margin-bottom: 0;
				}
			}

			&.search-for-tag {
				> div {
					@extend %truncate;
					padding: $space-md;
					font-size: .875rem;
					font-weight: 400;
					line-height: 1rem;
					background-color: $white;
					border-radius: 4px;

					.ip-search-icon {
						margin-right: 5px;
						font-size: inherit;
						font-weight: 600;
						color: $primary;
					}
				}
			}

			&.search-for-highlight {
				> div {
					@extend %highlight-title;
				}
			}

			&:nth-last-of-type(n+2) {
				margin-right: $space-md;
			}
			
		}

		input {
			width: 100%;
			height: 100%;
			min-width: 100px;

			&.slim {
				width: 8px;
				min-width: 8px;
				padding: $space-md 0;
			}
		}
	}

	.search-icon,
	.search-information-toggle {
		cursor: pointer;
		position: absolute;
		top: calc(50% - calc(#{$search-information-icon-font-size} / 2));
		font-size: $search-information-icon-font-size;
	}

	.search-icon {
		right: $space-xl;
	}

	.search-information-toggle {
		right: calc(-#{$space-lg} - calc(#{$search-information-icon-font-size} / 2));
		color: $quinary;
	}

	.search-dropdown {
		position: absolute;
		top: $header-height;
		right: 0;
		left: 0;
		padding: 0;
		font-weight: 600;
		list-style: none;
		cursor: pointer;
		background-color: $white;
		border-top: 2px solid $primary;
		box-shadow: $box-shadow;

		&.initial {
			li {
				padding: $space-lg $space-xl;
			}
		}

		li {
			display: flex;
			align-items: center;
			padding: $space-md $space-xl;

			&:first-child {
				padding-top: calc($space-xl / 2);
			}

			&:last-child {
				padding-bottom: calc($space-xl / 2);
			}

			&.search-for-highlight {
				> div {
					@extend %highlight-title;
					margin-bottom: 0;
				}
			}

			&:hover {
				background-color: $septenary;
			}

			.ip-search-icon {
				margin-right: $space-md;
				font-size: 1.375rem;
				color: $primary;
			}
		}

		.highlight-term {
			font-weight: 400;
		}
	}
}
