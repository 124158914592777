@import 'variables';

.open-file {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 416px;
	height: 248px;
	cursor: pointer;
	background-color: $septenary;
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgba(#{$open-file-border-rgba}, 1)' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

	.open-file-message {

		.drag-file {
			height: 55px;
			margin-bottom: $space-md;
		}

		p {
			margin-bottom: 0;
		}
	}
}
