$btn-display:                       inline-block !default;
$btn-width:                         auto !default;
$btn-margin:                        0 !default;
$btn-font-family:                   $font-primary !default;
$btn-font-size:                     .875rem;
$btn-font-weight:                   600;
$btn-line-height:                   $line-height !default;
$btn-text-decoration:               none !default;
$btn-white-space:                   nowrap !default;
$btn-border:                        1px solid transparent !default;
$btn-border-radius:                 $border-radius;
$btn-outline:                       none !default;
$btn-user-select:                   none !default;
$btn-appearance: 					none !default;
$btn-transition:                    .2s $ease-in-out-expo all, .15s $ease-in-out-sine transform;

/* three different button colors per default */
$btn-primary-color:                 $primary !default;
$btn-primary-color-hover:           $primary-d1;
$btn-secondary-color:               $septenary;
$btn-secondary-color-hover:         $septenary-d1;
$btn-tertiary-color:                $tertiary !default;

/* three different button sizes per default */
$btn-padding:                       calc($line-height / 3) calc($line-height) !default;
$btn-sm-padding:                    calc($line-height / 3) calc($line-height / 1.5) !default;
$btn-lg-padding:                    calc($line-height / 3.25) calc($line-height / 1.5) !default;
$btn-font-size-sm:						$font-size-sm !default;
$btn-font-size-lg:						$font-size-lg !default;

/* disabled buttons */
$btn-disabled-background-color:     $light-gray !default;
$btn-disabled-border-color:         $light-gray !default;

/* button active hover */
$btn-active-transform:              scale(.98);
