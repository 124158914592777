// import the atom variables
@import 'variables';

%heading {
	margin-top: 0;
	font-family: $heading-font;
	font-style: $heading-font-style;
	font-weight: $heading-font-weight;
	line-height: $heading-line-height;
	color: $heading-color;
	text-rendering: $heading-text-rendering; // Fix the character spacing for headings
	text-transform: $heading-text-transform;
}

h1 {
	margin-bottom: $space-xl;
	font-size: $h1-font-size;
}

h2 {
	margin-bottom: $space-md * 5;
	font-size: $h2-font-size;
	font-weight: 600;
}

h3 {
	margin-bottom: $space-md;
	font-size: $h3-font-size;
	font-weight: 600;
}

h4 {
	font-size: $h4-font-size;
}

h5 {
	font-size: $h5-font-size;
}

h6 {
	font-size: $h6-font-size;
}

