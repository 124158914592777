@import 'variables';

.leaf-through-navigation {
	position: fixed;
	bottom: calc(50vh - #{calc($leaf-through-navigation-height / 2)});
	width: $leaf-through-navigation-height;
	height: $leaf-through-navigation-height;
	overflow: hidden;
	cursor: pointer;
	background-color: $white;
	transition: all .075s $ease-in-sine;

	.ip-search-icon {
		position: absolute;
		bottom: calc(#{calc($leaf-through-navigation-height / 2)} - .5rem);
		margin: 0 20px;
		font-size: 1rem;
		color: $primary;
		text-align: center;
	}

	.preview {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		width: calc(320px - #{$leaf-through-navigation-height});
		height: 100%;
		padding-bottom: calc(#{calc($leaf-through-navigation-height / 2)} - .5rem - 3px);
		background-color: inherit;
		opacity: 0;
		transition: opacity .075s $ease-out-sine;
		transition-delay: 0;

		.title {
			position: relative;
			display: -webkit-box;
			width: 100%;
			height: 2.4rem;
			overflow: hidden;
			font-size: .875rem;
			line-height: 1.2rem;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;

		}

		.pub-number {
			font-weight: bold;
			color: $primary;
		}

	}

	&:hover {
		width: 320px;
		height: 95px;
		overflow: unset;
		background-color: $septenary;
		transition: all .25s $ease-out-sine;

		.preview {
			display: flex;
			opacity: 1;
			transition: opacity .2s $ease-in-sine;
			transition-delay: .06s;
		}
	}

	&.previous {
		left: 0;

		.ip-search-icon {
			transform: rotate(90deg);
		}

		.preview {
			float: left;
			padding-right: $space-lg;
			padding-left: $space-lg;
			margin-left: $leaf-through-navigation-height;
		}
	}

	&.next {
		right: 0;

		&.notes-active {
			@media screen and (max-width: 1343px) {
				right: 360px;
			}
			right: calc(25% + 28px);
		}

		.ip-search-icon {
			right: 0;
			transform: rotate(270deg);
		}

		.preview {
			float: right;
			padding-right: $space-lg;
			padding-left: $space-xl;
			margin-right: $leaf-through-navigation-height;
			text-align: right;
		}
	}
}
