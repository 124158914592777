@import 'variables';

.results-chapter-selector {
	position: relative;
	width: max-content;
	max-width: 60%;

	h2 {
		position: relative;
		margin-bottom: 0;
		cursor: pointer;

		> span {
			display: block;
		}
	}

	.ip-search-icon {
		top: 10px;
		left: -24px;
		font-size: 1rem;
		color: $primary;
	}

	.drop-down {
		@include slide-open-animaiton(800px, .2s, .125s);
		top: $space-xl + $space-lg;
		left: - $chapter-select-dropdown-padding-hor;
		width: calc(100% + #{$chapter-select-dropdown-padding-hor} + 45px);
		height: 60vh;
		min-width: 420px;
		border-top: 1px solid $primary;

		ul {
			padding: 0;
			margin: 0;
		}


		li {
			padding: $space-md $chapter-select-dropdown-padding-hor + $space-sm;
			font-size: 1.125rem;
			font-weight: 600;

			&.current {
				position: relative;

				&::before {
					position: absolute;
					top: 18px;
					left: 17px;
					display: inline-block;
					width: 7px;
					height: 7px;
					background-color: $primary;
					border-radius: 100%;
					content: '';
				}
			}

			&:hover {
				background-color: $septenary;
				
			}

			&:nth-child(1) {
				padding-top: $space-lg;

				&.current::before {
					top: 27px;
				}
			}

			&:nth-last-child(1) {
				padding-bottom: $space-lg;
			}
		}
	}
}
