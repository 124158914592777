.external-html {
	img {
		width: auto;
		max-width: 60%;
		max-height: 50vh;
		cursor: pointer;
	}

	ul {
		list-style: none;
		padding: 0;

		ul {
			padding-left: 14px;
		}

		li:before {
			content: "•";
			margin-right: 8px;
		}
	}

	p {
		hyphens: auto;
	}
}