// import the atom variables
@import 'variables';

%description-list {
	margin: $dl-margin;
	font-family: $dl-font-family;

	dt {
		@extend %label;
		margin: 0;
		font-size: .75rem;
		font-weight: $dt-font-weight;

		&:nth-child(n+2) {
			margin-top: $space-xl;
		}
	}

	dd {
		margin: $dd-margin;
	}
}
