.welcome {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;

	p {
		margin-bottom: $space-md * 5;
	}
}
