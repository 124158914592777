.ls-group {
	&::before { 
		display: inline-block;
		width: 8px;
		height: 8px;
		margin-right: 5px;
		margin-bottom: 1px;
		// background-color: gray;
		border-radius: 100%;
		content: '';
	}

	&.APPLIED::before {
		background-color: #333300;
	}

	&.LSAL::before {
		background-color: #FFFF00;
	}

	&.LSAS::before {
		background-color: #663300;
	}

	&.LSDW::before {
		background-color: #FF1493;
	}

	&.LSES::before {
		background-color: #aaaa00;
	}

	&.LSFE::before {
		background-color: #20B2AA;
	}

	&.LSGT::before {
		background-color: #008000;
	}

	&.LSLE::before {
		background-color: #000000;
	}

	&.LSLI::before {
		background-color: #CC3300;
	}

	&.LSNE::before {
		background-color: #220022;
	}

	&.LSNP::before {
		background-color: #aa00aa;
	}

	&.LSOP::before {
		background-color: #FFA500;
	}

	&.LSPD::before { //no color definition found
		background-color: white;
		border: 1px solid cyan;
	}

	&.LSPB::before {
		background-color: #1E90FF;
	}

	&.LSRE::before {
		background-color: #b3db32;
	}

	&.LSRV::before {
		background-color: #FF0000;
	}

	&.LSRX::before {
		background-color: #808080;
	}

	&.LSWD::before {
		background-color: #FF1493;
	}

	&.PEXP::before {
		background-color: #e74c3c;
	}

	.link {
		color: $primary;
		font-weight: 700;
		text-decoration: underline;

		&:hover {
			color: $primary;
		}
	
		&:active {
			color: $primary;
			outline: none;
		}
	
		&:focus {
			color: $primary;
			outline: none;
		}
	
		&:visited {
			color: $primary;
		}
	}
}
