.report-navigation {

	nav {
		position: fixed;
		width: inherit;

		ul {
			padding: 0;
			margin: 0;
			font-size: 0.875rem;
			font-weight: 400;

			li {
				line-height: 32px;

				&.active {
					font-weight: 600;
					color: $primary;
				}

				b {
					cursor: auto;
					font-weight: 600;
				}

				> ul > li {
					cursor: pointer;

					
				}

				> ul > li:nth-of-type(3){
					margin-bottom: $space-xl;
				}
			}
		}
	}
}
