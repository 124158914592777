// import the atom variables
@import 'variables';

// mixin for btn-regular
@mixin btn-regular($color, $hover-color) {
	color: set-custom-contrast-color($color, $white, $black);
	background-color: $color;

	&:hover {
		color: set-custom-contrast-color($hover-color, $white, $black);
		background-color: $hover-color;
	}
}
// mixin for btn-ghost
@mixin btn-ghost($color) {
	color: $color;
	background-color: transparent;
	border-color: $color;

	&:hover {
		color: set-custom-contrast-color($color, $white, $black);
		background-color: $color;
	}
}

// button extend
%button {
	@include btn-regular($btn-primary-color, $btn-primary-color-hover);
	display: $btn-display;
	width: $btn-width;
	height: 42px;
	padding: $btn-padding;
	margin: $btn-margin;
	font-family: $btn-font-family;
	font-size: $btn-font-size;
	font-weight: $btn-font-weight;
	line-height: $btn-line-height;
	text-decoration: $btn-text-decoration;
	white-space: $btn-white-space;
	cursor: pointer;
	border: $btn-border;
	border-radius: $btn-border-radius;
	outline: $btn-outline;
	transition: $btn-transition;
	appearance: $btn-appearance;
	user-select: $btn-user-select;

	// sizes
	&.sm {
		padding: $btn-sm-padding;
		font-size: $btn-font-size-sm;
	}

	&.lg {
		padding: $btn-lg-padding;
		font-size: $btn-font-size-lg;
	}

	// colors
	&.secondary {
		@include btn-regular($btn-secondary-color, $btn-secondary-color-hover);
	}

	// disabled
	&:disabled,
	[disabled] {
		background-color: $btn-disabled-background-color;

		&:hover {
			background-color: $btn-disabled-background-color;
			border-color: $btn-disabled-border-color;
			border: 0;
		}
	}

	&:active {
		transform: $btn-active-transform;
	}

	&.icon-text {
		background-color: transparent;

		&:hover {
			color: $font-color;
			background-color: $septenary;
		}

		.ip-search-icon {
			font-size: 1.25rem;
		}
	}

	&.close {
		padding: 20px 18px;
		font-size: .75rem;
		line-height: 0;
		color: $font-color;
		cursor: pointer;
		background-color: transparent;

		&:hover {
			color: $red;
		}
	}
}

%ghost {
	@include btn-ghost($btn-primary-color);
}
