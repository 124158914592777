// import the atom variables
@import 'variables';

%link {
	color: $link-color;
	text-decoration: none;

	&:hover {
		color: $link-hover-color;
	}

	&:active {
		color: $link-active-color;
		outline: none;
	}

	&:focus {
		color: $link-focus-color;
		outline: none;
	}

	&:visited {
		color: $link-visited-color;
	}

	&.primary {
		color: $link-primary-color;

		&:hover {
			color: $link-primary-hover-color;
		}

		&:active {
			color: $link-primary-active-color;
			outline: none;
		}

		&:focus {
			color: $link-primary-focus-color;
			outline: none;
		}

		&:visited {
			color: $link-primary-visited-color;
		}
	}
}

a:-webkit-any-link {
	text-decoration: none;
	outline: 0;
}

a.icon {
	color: $primary;
}
