$heading-color:             $font-color !default;
$heading-font:              $font-primary !default;
$heading-font-weight:       700 !default;
$heading-font-style:        normal !default;
$heading-text-transform:    normal !default;
$heading-line-height:       1.5em !default;
$heading-text-rendering:    optimizeLegibility !default;

$h1-font-size:              set-font-size(26, 'px') !default; /* for px value (value, 'px'), for em value (value, 'em') */
$h2-font-size:              1.375rem; /* for px value (value, 'px'), for em value (value, 'em') */
$h3-font-size:              1.125rem; /* for px value (value, 'px'), for em value (value, 'em') */
$h4-font-size:              set-font-size(24, 'px') !default; /* for px value (value, 'px'), for em value (value, 'em') */
$h5-font-size:              set-font-size(20, 'px') !default; /* for px value (value, 'px'), for em value (value, 'em') */
$h6-font-size:              set-font-size(16.8, 'px') !default; /* for px value (value, 'px'), for em value (value, 'em') */
