/*
default variables
the default variables are used to set up your basic theme styles like fonts, basc typography, colors, borders, gradients and so on… this variables are used to style all atoms, molecules and organisms.
*/

/* responsive helpers */
$tablet-portrait: 768px;
$tablet-landscape: 1024px;
$phone-portrait: 375px;
$phone-landscape: 600px;
$visual-grid: false; // display the base grid
$visual-grid-color: #ff0000;
$visual-grid-index: front;
$max-width: 1280px;
$large-screen: 1600px;
$bp-nav-collapse: $tablet-portrait;


/* fonts */
$font-primary: 'Open Sans', sans-serif;
$font-secondary: $font-primary;

/* typography */
$font-size: 1em;
$font-size-sm: .75em;
$font-size-lg: 1.5em;
$line-height-var: 1.5;
$line-height: $font-size * $line-height-var;
$line-height-unitless: $line-height-var; // Strip units from line-height: https://developer.mozilla.org/en-US/docs/Web/CSS/line-height#Prefer_unitless_numbers_for_line-height_values


/* colors */
/* theme colors */
$primary: #FC8000;
$primary-d1: #E87600;
$secondary: #393221;
$tertiary: #54472E;
$quaternary: #7C6E4E;
$quinary: #AFA794;
$senary: #D6D3DB;
$septenary: #F5F3ED;
$septenary-d1: #EAE8E2;

/* alerts */
$red: #EB0C2E;
$green: #00C754;
$blue: #3BABF2;

/* gray scales */
$white: #ffffff;
$light-gray: #FAFAFA;
$dark-gray: #A8A8A8;
$black: #000000;

/* font colors */
$font-color: $secondary;

/* color maps for styleguide */
$main-color-defs: (
	primary: $primary,
	primary-d1: $primary-d1,
	secondary: $secondary,
	tertiary: $tertiary,
	quaternary: $quaternary,
	quinary: $quinary,
	senary: $senary,
	septenary: $septenary,
	septenary-d1: $septenary-d1
);

$signal-color-defs: (
	red: $red,
	green: $green,
	blue: $blue
);

$grayscale-color-defs: (
	white: $white,
	light-gray: $light-gray,
	dark-gray: $dark-gray,
	black: $black
);

$font-color-defs: (
	font-color: $font-color
);

/* border */
$border-radius: 0;
$border-color: $light-gray;
$border-style: solid;
$border-width: 1px;

/* spaces */
$golden-ratio: 1.618033;
$space-sm: 4px;
$space-md: 8px;
$space-lg: 16px;
$space-xl: 24px;

/* flex-grid */
$flex-grid-max-width: 1600px !default;
$flex-grid-columns: 12 !default;
$flex-grid-gap: 28px !default;

/* UI-Element sizes helpers */
$header-height: $space-xl * 3;
$footer-height: $space-xl * 3;

/* Global UI-Styles */
$box-shadow:
	0px 1.4px 2.1px -11px rgba(189, 143, 143, 0.024),
	0px 3.4px 5px -11px rgba(0, 0, 0, 0.034),
	0px 6.4px 9.4px -11px rgba(0, 0, 0, 0.037),
	0px 11.3px 16.8px -11px rgba(0, 0, 0, 0.042),
	0px 20.8px 31.3px -11px rgba(0, 0, 0, 0.059),
	0px 48px 75px -11px rgba(0, 0, 0, 0.1)
;
