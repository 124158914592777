$textarea-width:                100% !default;
$textarea-padding:              calc($line-height / 3) calc($line-height / 3) !default;
$textarea-margin:               0 0 calc($line-height / 2) 0 !default;
$textarea-background-color:     $septenary;
$textarea-border:               $border-width $border-style $border-color !default;
$textarea-border-radius:        $border-radius !default;
$textarea-outline:              none !default;
$textarea-box-shadow:           none !default;
$textarea-box-sizing:           border-box !default;
$textarea-resize:               vertical !default;
