// import the atom variables
@import 'variables';

%hr {
	margin: $hr-margin;
	border-top: $hr-border-top;
	border-right: $hr-border-right;
	border-bottom: $hr-border-bottom;
	border-left: $hr-border-left;
}
