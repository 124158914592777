// import the atom variables
@import 'variables';

%label {
	display: $label-display;
	margin: $label-margin;
	font-weight: $label-font-weight;
	line-height: $label-line-height;
	color: $label-color;
	text-transform: uppercase;

	&.required {
		&::after {
			content: $label-required-after-content;
		}
	}

	abbr {
		display: $label-abbr-display;
	}
}
