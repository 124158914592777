@import 'variables';

.input-switch {
	display: flex;

	label {
		position: relative;
		display: flex;
		align-items: center;
		font-weight: 600;
		color: $quinary;
		text-transform: unset;
		cursor: pointer;
		transition: color .2s linear;

		&.active {
			color: $font-color;
		}

		&:first-of-type {

			input[type='radio'] {
				z-index: 1;

				&:not(:checked) {
					z-index: 2;
				}
			}
		}

		&:last-of-type {

			input[type='radio'] {
				position: absolute;
				top: 0;
				left: 12px;
				margin: 0;
				opacity: 0;
				transform: translate(-150%, 0);

				&:checked {
					z-index: 1;
				}

				&:not(:checked) {
					z-index: 2;
				}
			}
		}

		input {
			width: $switch-width;
			height: $switch-height;
			min-width: $switch-width;
			margin-right: $switch-margin;
			margin-left: $switch-margin;
			line-height: $switch-height;
			cursor: pointer;
			background-color: $quinary;
			border-radius: $switch-border-radius;
			transition: background-color .2s ease-in;
			appearance: none;
			-webkit-appearance: none;

			&::before {
				position: absolute;
				top: 50%;
				bottom: 10%;
				width: $inside-switch-width;
				height: $inside-switch-width;
				background-color: $white;
				border-radius: $switch-border-radius;
				content: '';
				
				transform: translate(10% , -49%);
				transition: all .2s $ease-in-out-quint;

				&:not(:checked)::before {
					transition: all .2s $ease-in-out-quint;
				}
			}

			&:checked {
				background-color: $green;

				&::before {
					transform: translate(#{$switch-width - 20px} , -49%);
				}
			}

			&[type='radio'] {
				background-color: $green;

				&::before {
					transform: translate(#{$switch-width - 20px} , -49%);
				}

				&:checked {

					&::before {
						transform: translate(10% , -49%);
					}
				}
			}
		}
	}
}
