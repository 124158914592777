.header {
	position: fixed;
	z-index: 1000;
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: $header-height;
	padding: 0 $space-xl * 2;
	background-color: $white;
	border-bottom: 1px solid $septenary;

	.menu,
	.brand {
		flex: 1 0 25%;
	}

	.search {
		flex: 2 0 50%;
		max-width: 50%;
	}
}
