@import 'variables';

.note {
	position: relative;
	padding: $note-padding;
	border-top: 2px solid $septenary;

	time {
		display: block;
		margin-bottom: $space-lg;
		font-size: .75rem;
		color: $quaternary;
	}

	.icon-delete {
		position: absolute;
		top: $note-padding;
		right: $note-padding;
		color: $senary;
		cursor: pointer;

		&:hover {
			color: $red;
		}
	}

	p {
		font-size: 1rem;
	}
}
