.results {
	padding-top: $space-xl;
	padding-bottom: $space-xl * 3;

	.results-header {
		display: flex;
		align-items: baseline;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-bottom: $space-xl + $space-lg;
	}
}
