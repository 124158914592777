.table-header {
	padding: $space-md 12px;
	overflow: hidden;
	font-size: .75rem;
	color: $quaternary;
	text-overflow: ellipsis;
	text-transform: uppercase;
	white-space: nowrap;
	user-select: none;

	&.sortable {
		display: flex;
		align-items: flex-end;
		cursor: pointer;

		&::after {
			@extend .ip-search-icon;
			display: inline-block;
			margin-left: 6px;
			margin-bottom: 1px;
			content: $icon-arrow-down;
			opacity: .25;
			transform: rotate(180deg);
			transition: transform .085s $ease-out-sine;
		}

		&.active {
			&::after {
				opacity: 1;
			}
		}

		&.descending {
			&::after {
				transform: rotate(0deg);
			}
		}
	}
}
