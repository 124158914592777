@import 'variables';

.loading-animation {
	position: relative;
	width: $loading-animation-size;
	height: $loading-animation-size;

	div {
		position: absolute;
		border-radius: 50%;
		
		transform: rotate(1turn);
		animation-duration: 1s;
		animation-iteration-count: infinite;
		animation-name: spin;
		animation-timing-function: linear;
		transition: transform;

		&:nth-child(1) {
			top: ($loading-animation-line + $loading-animation-gap) * 2;
			left: ($loading-animation-line + $loading-animation-gap) * 2;
			width: $loading-animation-size - (($loading-animation-line + $loading-animation-gap) * 4);
			height: $loading-animation-size - (($loading-animation-line + $loading-animation-gap) * 4);
			border: $loading-animation-line solid $loading-animation-small-color;
			border-left: $loading-animation-line solid transparent;
			filter: blur(0);
			animation-delay: .2s;
			transition-duration: .2s;
		}

		&:nth-child(2) {
			top: $loading-animation-line + $loading-animation-gap;
			left: $loading-animation-line + $loading-animation-gap;
			width: $loading-animation-size - (($loading-animation-line + $loading-animation-gap) * 2);
			height: $loading-animation-size - (($loading-animation-line + $loading-animation-gap) * 2);
			border: $loading-animation-line solid $loading-animation-middle-color;
			border-right: $loading-animation-line solid transparent;
			filter: blur(.4px);
			animation-delay: .1s;
			transition-duration: .4s;
		}

		&:nth-child(3) {
			width: $loading-animation-size;
			height: $loading-animation-size;
			border: $loading-animation-line solid $loading-animation-large-color;
			border-bottom: $loading-animation-line solid transparent;
			filter: blur(.6px);
			animation-delay: .2s;
			transition-duration: .1s;
		}
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	50% {
		transform: rotate(180deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

