// import the atom variables
@import 'variables';

%code {
	padding: $code-padding;
	font-family: 'Lucida Console', Monaco, monospace;
	font-size: .875em;
	background-color: $code-background-color;
	border-radius: $code-border-radius;
}
