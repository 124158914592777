.results-table {

	.table-head {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		border-left: 2px solid transparent;
	}

	> .row {
		position: relative;
		cursor: pointer;
		border-left: 2px solid transparent;

		&.active {
			border-left: 2px solid $primary;
			margin-bottom: $space-lg;
			box-shadow:
				0px 0.1px 0.2px rgba(0, 0, 0, 0.02),
				0px 0.3px 0.5px rgba(0, 0, 0, 0.028),
				0px 0.5px 0.9px rgba(0, 0, 0, 0.035),
				0px 0.9px 1.6px rgba(0, 0, 0, 0.042),
				0px 1.7px 2.9px rgba(0, 0, 0, 0.05),
				0px 4px 7px rgba(0, 0, 0, 0.07)
			;

		}

		&:nth-child(odd) {
			background-color: $light-gray;

			&.active:hover {
				background-color: $light-gray;
			}
		}

		&:nth-child(even) {
			background-color: $white;

			&.active:hover {
				background-color: $white;
			}
		}

		&:hover {
			z-index: 10;
			background-color: $septenary;
			box-shadow:
				0 3.5px 2.8px -10px rgba(0, 0, 0, .012),
				0 4.9px 4px -10px rgba(0, 0, 0, .022),
				0 5.9px 5.3px -10px rgba(0, 0, 0, .031),
				0 7.3px 8.3px -10px rgba(0, 0, 0, .044),
				0 12px 14px -10px rgba(0, 0, 0, .07)
			;

		}
	}

	.table-head .table-header,
	> .row .main-data .table-data {
		

		&:nth-child(1) {
			flex-basis: 10%;
			min-width: 125px;
		}

		&:nth-child(2) {
			flex-basis: 5%;
			max-width: 50px;
			min-width: 20px;
		}

		&:nth-child(3) {
			flex-basis: 45%;
			flex-shrink: 1000;
		}

		&:nth-child(4) {
			flex-basis: 7%;
			min-width: 110px;
		}

		&:nth-child(5) {
			flex-basis: 20%;
		}

		&:nth-child(6) {
			flex-basis: 7%;
		}

		&:nth-child(7) {
			flex-basis: 6%;
			max-width: 50px;
			padding-left: 2px;
		}

		&:nth-child(8) {
			width: 30px;
		}

		&.centered {
			text-align: center;
			justify-content: center;
		}
	}

	.main-data,
	.additional-data {
		display: flex;
		flex-wrap: nowrap;

		.link,
		a {
			font-weight: 700;
			color: $primary;
		}
	}

	.main-data {
		justify-content: space-between;

		> div {
			flex-shrink: 0;
		}

		.actions {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			width: 30px;


			.expand-arrow {
				font-size: .875rem;
			}
		}
	}

	.additional-data {
		@include slide-open-animaiton(90px, .15s, .05s);
		align-items: flex-end;

		&.enter-active,
		&.enter-done {
			padding-top: $space-sm;
			padding-bottom: $space-xl;
		}

		&.exit-start {
			padding-top: $space-sm;
			padding-bottom: $space-xl;
		}

		.table-data {
			&:nth-child(1) {
				flex-basis: 20%;
				flex-grow: 1;
			}
		}

		.actions {
			display: flex;
			margin-left: auto;

			button {
				margin-right: 20px;
			}
		}
	}

	.has-family-members {
		.family-members-table .additional-data {
			box-shadow: unset;
		}
	}

	&.npl {
		.table-head .table-header,
		> .row .table-data {

			&:nth-child(1) {
				flex-basis: 62%;
				flex-shrink: 5;
				min-width: 200px;
				max-width: unset;
				flex-grow: 1;
			}

			&:nth-child(2) {
				flex-basis: 25%;
				flex-shrink: 1;
				max-width: unset;
				min-width: unset;
			}

			&:nth-child(3) {
				flex-basis: 7%;
				min-width: unset;
				flex-shrink: unset;
			}

			&:nth-child(4) {
				flex-basis: 6%;
				min-width: unset;
				max-width: 50px;
				padding-left: 2px;
			}
	
			&:nth-child(5) {
				flex-basis: unset;
				width: 30px;
				flex-grow: 0;
			}

		}
	}	

	&.stn-registry {
		.table-head .table-header,
		> .row .table-data {

			&:nth-child(1) {
				flex-basis: 10%;
				flex-shrink: 5;
				min-width: 150px;
				max-width: unset;
				flex-grow: 1;
			}

			&:nth-child(2) {
				flex-basis: 84%;
				flex-shrink: 1;
				max-width: unset;
				min-width: unset;
			}

			&:nth-child(3) {
				flex-basis: 6%;
				min-width: unset;
				max-width: 50px;
				padding-left: 2px;
			}
	
			&:nth-child(4) {
				flex-basis: unset;
				width: 30px;
				flex-grow: 0;
			}

		}
	}	
}
