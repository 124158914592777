
.menu {
	height: $header-height;

	ul {
		list-style: none;
	}

	.main-menu {
		display: flex;
		height: 100%;
		padding: 0;
		font-weight: 600;

		> .menu-item {
			position: relative;

			> div {
				display: inline-flex;
				flex-direction: column;
				justify-content: center;
				height: 100%;
				padding: 0 $space-md;
				margin-right: $space-xl * 2;
				cursor: pointer;
				border-top: 4px solid transparent;
				border-bottom: 4px solid transparent;

				&:hover,
				&.active {
					border-bottom: 4px solid $primary;
				}
			}
		}
	}

	.sub-menu {
		position: absolute;
		z-index: 1100;
		display: none;
		width: 180px;
		padding-left: 0;
		font-weight: 400;
		cursor: pointer;
		background: $white;
		box-shadow: $box-shadow;


		&.active {
			display: block;
		}

		.menu-item {
			padding: $space-md $space-lg;

			&:hover {
				background-color: $septenary;
			}

			&.disabled {
				color: $dark-gray;
				cursor: default;

				&:hover {
					background-color: $white;
				}

				.icon-text,
				.ip-search-icon {
					color: inherit;
				}
			}

			&.separated {
				border-top: 1px solid $septenary;
			}

			.icon-text {
				font-weight: 400;
			}
		}
	}
}

