.drop-down {
	position: absolute;
	z-index: 400;
	min-width: max-content;
	padding: 0;
	cursor: pointer;
	background: $white;
	border-bottom-right-radius: $border-radius;
	border-bottom-left-radius: $border-radius;
	box-shadow: $box-shadow;

	li {
		list-style: none;

		&:hover {
			background-color: $septenary;
		}
	}
}
	