.results-control {
	display: flex;
	align-items: baseline;
	justify-content: flex-end;
	width: max-content;
	max-width: 38%;


	> *:nth-last-child(n+2) {
		margin-right: 20px;
	}
}
