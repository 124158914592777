.table-data {
	padding: $space-md 12px;
	
	&[data-label]::before {
		display: block;
		overflow: hidden;
		font-size: .75rem;
		color: $quaternary;
		text-overflow: ellipsis;
		text-transform: uppercase;
		white-space: nowrap;
		content: attr(data-label);
	}

	.link {
		display: block;
	}

	.icon-notes-indicator {
		color: $primary;
		font-size: 12px;
		padding-top: 7px;
	}
}