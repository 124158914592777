// import the atom variables
@import 'variables';

%table {
	width: $table-width;
	margin: $table-margin;
	border-collapse: $table-border-collapse;
	table-layout: $table-table-layout;
}

%th {
	padding: $th-padding;
	font-weight: $th-font-weight;
	text-align: $th-text-align;
	vertical-align: $th-vertical-align;
	border-bottom: $th-border-bottom;
}

%td {
	padding: $td-padding;
	vertical-align: $td-vertical-align;
	border-bottom: $td-border-bottom;
}

%tr {
	vertical-align: $tr-vertical-align;
}
