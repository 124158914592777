@import 'variables';

.highlight-terms {
	margin-bottom: $space-lg * 2;

	header {
		position: relative;
		display: flex;
		align-items: center;
		margin-bottom: $space-lg;

		.icon-delete {
			position: absolute;
			right: 0;
			color: $senary;
			cursor: pointer;

			&:hover {
				color: $red;
			}
		}
	}

	.highlight-terms-icon {
		position: relative;
		margin-right: 22px;

		> div:first-child {
			position: absolute;
			top: 1px;
			left: 1px;
			width: $icon-color-dot-size;
			height: $icon-color-dot-size;
			border-radius: $icon-color-dot-size;
		}

		.ip-search-icon {
			position: relative;
			z-index: 10;
		}
	}

	.highlight-terms-title {
		margin-right: $space-xl;
		font-size: 1.125rem;
		font-weight: 600;
	}

	.highlight-terms-count {
		font-style: italic;
		color: $quaternary;
	}

	ul {
		display: flex;
		align-items: baseline;
		flex-wrap: wrap;
		padding-left: 39px;
		list-style-type: none;

		li {
			display: flex;
			align-items: center;
			margin-right: calc($space-xl / 2);
			margin-bottom: calc($space-xl / 2);
			font-size: .875rem;
			border-radius: $border-radius;

			&:nth-last-child(n+2) {
				padding-left: $space-md;
			}

			.icon-add,
			.icon-close {
				display: flex;
				align-items: center;
				justify-content: center;
				width: $highlight-term-height;
				height: $highlight-term-height;
				font-size: .5rem;
				cursor: pointer;
			}
		}

		.highlight-term-add {
			font-size: .875rem;
			font-weight: 600;
			cursor: pointer;

			.ip-search-icon {
				font-size: 1rem;
				font-weight: 700;
				color: $primary;
			}

			&:hover {
				color: $primary;
			}
		}
	}
}
