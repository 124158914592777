@page {
	margin: 18mm 10mm 16mm 15mm; 
	size: A4;
}

@media print {
	// GENERAL
	html,
	body {
		height: 100%;
	}

	* {
		-webkit-print-color-adjust: exact !important;   /* Chrome, Safari, Edge */
		color-adjust: exact !important;                 /*Firefox*/
	}

	.Toastify {
		display: none;
	}

	// HEADER
	.header {
		border-bottom: none;
		background-color: transparent;
		position: relative;
		padding: 0;
		margin-bottom: $space-xl;
	}

	.menu,
	.search {
		display: none;
	}

	.brand {
		justify-content: flex-start;
	}


	// CONTENT
	.view {
		margin-top: 0;
		padding: 0;
		background-color: transparent;
	}

	.ScrollbarsCustom-Wrapper,
	.ScrollbarsCustom-Scroller {
		position: unset !important;
		height: max-content;
	}

	h2 {
		break-before: avoid-page;
		font-size: 17pt;
		margin-bottom: $space-xl;
	}

	h3 {
		font-size: 14pt;
	}
	
	p,
	ul,
	ol {
		break-inside: avoid-page;
		font-size: 13pt;
	}

	img {
		break-inside: avoid-page;
	}


	// REPORT
	.report {
		.report-navigation {
			display: none;
		}
	
		.report-content {
			width: 100%;

			p {
				font-size: 14pt;
			}

			.report-content-section {
				break-inside: avoid-page;

				& + .report-content-section {
					margin-top: 12mm;
				}
			}
		}
	}


	// RESULT TABLES
	.results,
	.search-results {
		.results-header {
			padding-bottom: 12px;

		}

		.results-information {
			header {
				display: none;
			}

			p {
				columns: unset;
				grid-column-gap: unset;
				column-gap: unset;
			}
		}

		.results-control,
		.results-chapter-selector .drop-down,
		.expand-arrow {
			display: none;
		}

		.search-results-chapter-heading {
			max-width: 100%;

			h3 {
				max-width: 80%;
			}

			.search-results-chapter-count {
				max-width: 20%;
			}

		}
		
		.results-table {
			.table-header {
				font-size: 7pt;
				padding: 4px 6px;

				&.sortable::after {
					content: '';
				}
			}

			.table-head .table-header,
			> .row .main-data .table-data {
				&:nth-child(8) {
					display: none;
				}
			}

			&.npl {
				.table-head .table-header,
				> .row .table-data {
					&:nth-child(5) {
						display: none;
					}
				}
			}
			
			&.stn-registry {
				.table-head .table-header,
				> .row .table-data {
					&:nth-child(4) {
						display: none;
					}
				}
			}
			
			.main-data .actions,
			.additional-data .actions {
				display: none;
			}

			.additional-data.enter-done {
				padding-bottom: 8px;
				padding-top: 0;

				.table-data {
					padding-top: 0;
				}
			}

			.table-data {
				font-size: 10pt;
				padding: 4px 6px;

				.icon-notes-indicator {
					padding-top: 5px;
				}
			}

			.table-data[data-label]::before {
				font-size: 7pt;
			}

			.ls-group {

				&::before {
					content: unset;
					// width: 4px;
					// height: 4px;
					// margin-right: 2px;
					// margin-bottom: 0;
				}
			}

			.family-members-table {
				height: max-content !important;
				max-height: unset !important;

				

				.row {
					break-inside: avoid-page;
				}
			}
		}
	}


	// CITATION
	.leaf-through-navigation {
		display: none;
	}
	.citation {
		.citation-header {
			.citation-control {
				padding: 0 0 0 $space-lg;

				&.sticky {
					position: unset;
					width: unset;
					top: unset;
				}

				.ip-search-icon,
				.citation-control-action {
					display: none;
				}

				.pub-number {
					margin-left: 0;
					text-decoration: none;
				}
			}
		}

		.citation-content {
			.label {
				padding-left: 0;
				width: calc(20% - 28px);
			}

			.data {
				width: calc(80% - 28px);
			}

			.citation-tags {
				button,
				.input-icon-prefix {
					display: none;
				}
			}
		}

		.notes-active {
			padding-bottom: $space-xl;
		}

		.notes {
			position: unset;
			width: 100%;

			.notes-title {
				font-size: 14pt;
				margin-bottom: 8px;
				padding-left: 0;
			}

			.notes-new,
			.close {
				display: none;
			}

			
			.notes-existing {
				.note {
					break-inside: avoid-page;
					padding: 12px 0;
					
					time {
						margin-bottom: $space-md;
					}

					p {
						margin-bottom: $space-md;
						font-size: 12pt;
					}

					.ip-search-icon {
						display: none;
					}
				}
			}
		}
	}


	// HIGHLIGHTS
	.highlights {
		.highlight-controls,
		.icon-delete,
		.icon-close		
		{
			display: none;
		}

		.highlight-content {
			width: 100%;
		}

		.highlight-terms {
			break-inside: avoid-page;

			header {
				break-after: avoid;
			}
			
			ul {
				break-before: avoid;

				li {
					height: 32px;
					padding-right: 8px;

					.icon-close {
						display: none;
					}

					&:last-child {
						display: none;
					}
				}
			}	
		}
	}


	// FOOTER
	.footer {
		display: block;
		margin-top: 12mm;
		background-color: transparent;
		padding: 0;
		min-height: unset;

		> *:nth-child(1) {
			float: left;
		}

		> *:nth-child(2) {
			float: right;
		}

		li:not(:last-child) {
			display: none;
		}
	}

	// BROWSER SPECIFIC
	@-moz-document url-prefix() {
		.footer {
			display: none;
		}
	}
}