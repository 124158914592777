// import the atom variables
@import 'variables';

/**
*     @additional information
*     all inputs are basically extended with %input and all text inputs with %input and %focus
*     checkbox, fileupload, radiobutton, submit, search and select have own atom setups
*/
%focus {
	&:focus {
		color: $input-focus-color;
		border-color: $input-focus-border-color;
	}
}

%input {
	display: $input-display;
	width: $input-width;
	padding: $input-padding;
	margin: $input-margin;
	background-color: $input-background-color;
	border: $input-border;
	border-radius: $input-border-radius;
	outline: $input-outline;
	box-shadow: $input-box-shadow;
	box-sizing: $input-box-sizing;
	line-height: $line-height;

	&::placeholder {
		padding: 0;
		font-weight: 400;
		color: $input-placeholder-color;
	}
}

input[type='date']::before,
input[type='datetime']::before,
input[type='datetime-local']::before,
input[type='time']::before,
input[type='month']::before {
	margin-right: $input-before-margin-right;
	color: $input-before-color;
	content: $input-before-content;
}

input[type="color"] {
	-webkit-appearance: none;
	width: 48px;
	height: 42px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 2px 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}
