// import the atom variables
@import 'variables';

%textarea {
	width: $textarea-width;
	padding: $textarea-padding;
	margin: $textarea-margin;
	background-color: $textarea-background-color;
	border: $textarea-border;
	border-radius: $textarea-border-radius;
	outline: $textarea-outline;
	box-shadow: $textarea-box-shadow;
	box-sizing: $textarea-box-sizing;
	resize: $textarea-resize;

	&::placeholder {
		color: $input-placeholder-color;
	}
}
