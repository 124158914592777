.citation-content {
	padding-top: $space-xl * 1.5;
	padding-bottom: $space-xl * 2;

	.label,
	.data {
		padding-bottom: $space-xl;
	}

	.label {
		padding-left: 68px;
		font-size: .875rem;
		color: $quaternary;
		text-transform: uppercase;
	}

	.data {
		font-size: 1.125rem;
		white-space: pre-wrap;
	}

	.data-sequence-alignment {
		
		// width: auto;
		// font-size: 1rem;
		white-space: unset;
		overflow-x: scroll;
		
		.external-html {
			font-family: 'Consolas', 'Monaco', monospace;
			line-height: 1.1;
			white-space: pre-wrap;
			width: max-content;
		}
	}
	
	.citation-image {
		max-width: 160px;
		cursor: pointer;
	}

	&.notes-active {
		width: 75%;
	}

	mark {
		background: unset;
		color: unset;
	}
}
