$layout-grid-columns: 12;
$layout-grid-gap: 28px;

.layout-grid {
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	> div[class^='col-'] {
		min-height: 50px;
	}

	@for $i from 1 through $layout-grid-columns {
		.col-#{$i}of#{$layout-grid-columns} {
			width: calc(#{calc($i / $layout-grid-columns * 100 * 1%)} - #{$layout-grid-gap}) ;
			margin-right: $layout-grid-gap;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}
