.tag {
	display: inline-flex;
	align-items: center;
	padding-right: 16px;

	&::before {
		@extend .ip-search-icon;
		display: block;
		margin-right: 4px;
		color: $primary;
		content: $icon-tag;
	}
}
