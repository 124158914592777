.#{$rt-namespace}__toast {
	position: relative;
	display: flex;
	justify-content: space-between;
	max-height: var(--toastify-toast-max-height);
	min-height: var(--toastify-toast-min-height);
	padding: 6px 8px;
	margin-bottom: 1rem;
	overflow: hidden;
	font-family: var(--toastify-font-family);
	cursor: pointer;
	border-radius: $border-radius;
	box-shadow: $box-shadow;
	box-sizing: border-box;
	direction: ltr;

	&--rtl {
		direction: rtl;
	}

	&-body {
		display: flex;
		align-items: center;
		flex: 1 1 auto;
		padding: 6px;
		margin: auto 0;
	}

	&-icon {
		display: flex;
		flex-shrink: 0;
		width: 20px;
		margin-inline-end: 10px;
	}
}

.#{$rt-namespace}--animate {
	animation-duration: 0.7s;
	animation-fill-mode: both;
}

.#{$rt-namespace}--animate-icon {
	animation-duration: 0.3s;
	animation-fill-mode: both;
}

@media #{$rt-mobile} {
	.#{$rt-namespace}__toast {
		margin-bottom: 0;
		border-radius: 0;
	}
}
