b {
	font-weight: 600;
}

%truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	
	* {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.truncate {
	@extend %truncate;
}

@mixin slide-open-animaiton($max-height, $open-time: .15s, $close-time: .15s) {
	&.enter {
		max-height: 0;

		> * {
			opacity: 0;
		}
	}

	&.enter-active {
		max-height: $max-height;
		transition: all $open-time $ease-out-sine;

		> * {
			opacity: 1;
			transition: all #{$open-time * .7} $ease-in-out-quint;
			transition-delay: #{$open-time * .2};
		}
	}

	&.exit {
		max-height: $max-height;

		> * {
			opacity: 1;
		}
	}

	&.exit-active {
		max-height: 0;
		transition: all $close-time $ease-out-sine;

		> * {
			opacity: 0;
			transition: all #{$close-time * .5} $ease-in-out-quint;
			transition-delay: #{$close-time * .2};
		}
	}
}
