%highlight-title {
	display: flex;
	align-items: center;
	height: 32px;
	padding: $space-md;
	margin-bottom: $space-md;
	font-size: .875rem;
	font-weight: 600;
	line-height: 1rem;
	border-radius: $border-radius;

	.ip-search-icon {
		margin-right: 5px;
		font-size: inherit;
		color: inherit;
	}

	>span:last-child {
		@extend %truncate;
	}
}
