$link-color:            			$font-color;
$link-hover-color:      			desaturate(darken($link-color, 15), 30) !default;
$link-active-color:     			desaturate(darken($link-color, 15), 30) !default;
$link-focus-color:      			desaturate(darken($link-color, 15), 30) !default;
$link-visited-color:					$link-color;
$link-primary-color:            	$primary;
$link-primary-hover-color:      	desaturate(darken($link-primary-color, 15), 30);
$link-primary-active-color:     	desaturate(darken($link-primary-color, 15), 30);
$link-primary-focus-color:      	desaturate(darken($link-primary-color, 15), 30);
$link-primary-visited-color:    	$link-primary-color;
