.input-icon-prefix {
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 0;

	.ip-search-icon {
		position: absolute;
		top: 12px;
		left: 8px;
		font-size: 1.25rem;
		color: $primary;
	}

	input,
	button {
		margin-bottom: 0;

		&[type='text'] {
			max-width: 300px;
			min-width: 120px;
			padding-left: 36px;
		}

		&:first-of-type {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			height: 42px;
		}

		&:last-of-type {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
		}
	}
}
