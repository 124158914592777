.highlight-add {
	margin-top: $space-xl;
	margin-bottom: $space-xl;

	.input-icon-prefix {
		margin-bottom: $space-lg;
	}

	input[type='text'] {
		max-width: 100%;
	}

	input[type='submit'],
	button {
		width: 100%;
		color: $font-color;
		transition: background-color .25s $ease-out-expo;

		&:disabled {
			color: $dark-gray;
			background-color: $senary;
		}
	}
}

.highlight-add-colors-list {
	display: grid;
	padding: 0;
	margin-bottom: $space-xl;
	list-style-type: none;
	grid-template-columns: repeat(8, 1fr);
	column-gap: $space-md;
	row-gap: $space-md;

	li {
		position: relative;
		display: block;
		width: 100%;
		cursor: pointer;
		aspect-ratio: 1;

		&:nth-child(8n) {
			margin-right: 0;
		}

		&.used {
			padding: $space-sm;
			overflow: hidden;
			cursor: not-allowed;
			border: 1px solid $dark-gray;

			&::before {
				position: absolute;
				top: -25%;
				left: 50%;
				height: 150%;
				border-left: 1px solid $dark-gray;
				content: '';
				transform: rotate(45deg);
				transform-origin: center center;
			}
		}

		&.selected::before {
			position: absolute;
			top: 25%;
			left: 25%;
			width: 50%;
			height: 50%;
			background-color: $white;
			content: '';
		}

		> div {
			width: 100%;
			height: 100%;
		}
	}
}
