.breadcrumbs {
	margin-bottom: $space-lg;

	.breadcrumb-item {
		font-size: .875rem;
		color: $quaternary;

		&.current {
			font-weight: 600;
		}

		&:not(.current) {
			cursor: pointer;

			&::after {
				display: inline-block;
				margin: 0 $space-sm;
				font-size: .6875rem;
				content: '/';
			}
		}
	}
}
