.footer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	min-height: $footer-height;
	padding: 18px 62px;
	margin-top: auto;
	font-size: .75rem;
	background-color: $septenary;

	nav ul {
		display: flex;
		padding: 0;

		li:nth-last-child(n+2) {
			margin-right: 13px;

			&::after {
				margin-left: 13px;
				content: '|';
			}
		}
	}
}
