/**
*     @additional information
*     all inputs are basically extended with the input atom. so do not wonder if there are more rules on this input type
*/

input[type='search'] {
	height: 100%;
	margin: 0;
	background-color: transparent;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	appearance: none;

	&::placeholder {
		color: $quaternary;
	}

}
