// import the atom variables
@import 'variables';

%img {
	width: $img-width;
	height: $img-height;
	max-width: $img-max-width;
	margin: $img-margin;
}

%img-centered {
	position: absolute;
	top: 50%;
	left: 50%;
	width: auto;
	min-width: 100%;
	min-height: 100%;
	transform: translate3d(-50%, -50%, 0);
}
